import React from "react"
import Particles from "react-particles-js"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <div
      className="content-wrapper"
      style={{
        height: `100%`,
      }}
    >
      <Particles
        style={{
          height: ``,
          position: `absolute`,
          top: `0`,
          left: `0`,
          bottom: `0`,
          margin: `auto`,
        }}
        params={{
          particles: {
            number: {
              value: 60,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <SEO title="Home" />
      <div
        className="flex"
        style={{
          display: `flex`,
          textAlign: `center`,
          flexDirection: `column`,
          maxWidth: `54ch`,
          padding: `16px`,
          paddingTop: `0`,
          height: `100%`,
          margin: `auto`,
          justifyContent: `center`,
        }}
      >
        <h1
          style={{
            color: `#fff`,
            fontSize: `48px`,
            fontWeight: `800`,
          }}
        >
          Hi, I am Alma
        </h1>
        <p
          style={{
            color: `#fff`,
            fontSize: `20px`,
            fontWeight: `400`,
          }}
        >
          I help businesses find and reach their audience online.
        </p>
        <p
          style={{
            color: `#fff`,
            fontSize: `20px`,
            fontWeight: `400`,
          }}
        >
          I’m a SEO analyst and consultant with a wide range of skills and
          expertise in all digital marketing related fields.
        </p>
        <p
          style={{
            color: `#fff`,
            fontSize: `20px`,
            fontWeight: `400`,
            paddingTop: `16px`
          }}
        >
          Over the past 5 years I’ve had the pleasure to create and deliver comprehensive strategies for a variety of globally reknown companies such as GE, Crucial, Fred Perry, Global Savings Group etc.
        </p>
        <Link
          style={{
            paddingTop: `16px`,
            paddingBottom: `16px`,
            paddingLeft: `32px`,
            paddingRight: `32px`,
            alignItems: `center`,
            backgroundColor: `white`,
            display: `inline-flex`,
            cursor: `pointer`,
            fontSize: `20px`,
            fontWeight: `600`,
            lineHeight: `1`,
            position: `relative`,
            zIndex: `2`,
            verticalAlign: `top`,
            color: `#292f35`,
            marginTop: `32px`,
            alignSelf: `center`,
            flex: `0 0 auto`,
          }}
          to="/services"
        >
          {" "}
          Services{" "}
        </Link>
      </div>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
    </div>
  </Layout>
)

export default IndexPage
